body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
  position: fixed;
  margin: 0;
  overflow-y: auto;
  background: linear-gradient(45deg, rgb(30, 1, 57), rgb(123 47 0));
}

.fileContainer {
  overflow: hidden;
  position: relative;
  font-family: "Signika";
  color: white;
}

.fileContainer [type="file"] {
  cursor: inherit;
  display: block;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
}

.clickable:hover,
.fileContainer:hover {
  cursor: pointer;
}

.iconContainer:hover {
  cursor: pointer;
  background: white;
}

.unclickable:hover {
  cursor: default;
}
